<div class="price-list-backdrop" (click)="closeSidebar.emit(true)"></div>
<div class="price-list">
  <div class="price-list__head">
    <div class="price-list__title" translate>
      Company shipping prices
    </div>
    <div class="price-list__close" (click)="closeSidebar.emit(true)">
      <i class="icon-close-stroke pointer fs-28"></i>
    </div>
  </div>
  <div class="price-list__filters-section">
    <div class="price-list__input input ap-filters__input">
      <i class="icon-search"></i>
      <input type="text" [formControl]="searchFormControl"
             placeholder="{{ 'Search by delivery method...' | translate }}">
    </div>
    <!-- <div class="price-list__btn-container">
      <button class="button button--height-40"
              (click)="onOpenFiltersContextMenu()">
        <i class="icon-filter mr--6"></i>
        Filter by carrier
      </button>
    </div> -->
  </div>
  <div class="price-list__prices-section" *ngIf="filteredBringProducts.length || filteredHelthjemProducts || filteredPostnordProducts">
    <div class="price-list__warning-container">
      <ap-ui-inline-alert [type]="'warning_blue'"
                          [description]="'Displayed prices do not include carrier surcharges for toll taxes, services, next day express, and fuel supplements for Svalbard. Additional costs may apply to your shipment.' | translate"
                          [title]="' Important Notice!' | translate"
                          [hideIcon]="true"
                          (close)="showPriceListWarning = false"
                          *ngIf="showPriceListWarning">
      </ap-ui-inline-alert>
    </div>
    <ng-container *ngFor="let product of filteredBringProducts">
      <div class="price-list__price price">
        <div class="price__top" (click)="product.isOpen = !product.isOpen">
          <div class="price__title" translate>
            {{ product.name }}
          </div>
          <div class="price__logo">
            <img [src]="'assets/images/shipments/bring.png'" alt="Bring logo">
          </div>
          <div class="price__items-count"
               [tooltip]="getItemsCountTooltip(product.weights)">
            {{ product.weights.length }}
          </div>
          <i class="icon-arrow-up-stroke ms-auto fs-20 pointer" [class.rotate-180deg]="!product.isOpen"></i>
        </div>
        <div class="price__details" *ngIf="product.isOpen">
          <div class="price__items">
            <div class="price-item" *ngFor="let weight of product.weights">
              <i class="price-item__icon icon-prices fs-20 mr--8"></i>
              <div class="price-item__column">
                <div class="price-item__title">{{ weight.weight }}</div>
                <div class="price-item__value">{{ weight.price + (weight.price_type === 'fix' ? ' NOK' : ' %')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let product of filteredHelthjemProducts">
      <div class="price-list__price price">
        <div class="price__top" (click)="product.isOpen = !product.isOpen">
          <div class="price__title" translate>
            {{ product.name }}
          </div>
          <div class="price__logo">
            <img [src]="'assets/images/shipments/helthjem.svg'" alt="Helthjem logo">
          </div>
          <div class="price__items-count"
               [tooltip]="getItemsCountTooltip(product.weights)">
            {{ product.weights.length }}
          </div>
          <i class="icon-arrow-up-stroke ms-auto fs-20 pointer" [class.rotate-180deg]="!product.isOpen"></i>
        </div>
        <div class="price__details" *ngIf="product.isOpen">
          <div class="price__items">
            <div class="price-item" *ngFor="let weight of product.weights">
              <i class="price-item__icon icon-prices fs-20 mr--8"></i>
              <div class="price-item__column">
                <div class="price-item__title">{{ weight.weight }}</div>
                <div class="price-item__value">{{ weight.price + (weight.price_type === 'fix' ? ' NOK' : ' %')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let product of filteredPostnordProducts">
      <div class="price-list__price price">
        <div class="price__top" (click)="product.isOpen = !product.isOpen">
          <div class="price__title" translate>
            {{ product.name }}
          </div>
          <div class="price__logo">
            <img [src]="'assets/images/shipments/postnord.png'" alt="Postnord logo">
          </div>
          <div class="price__items-count"
               [tooltip]="getItemsCountTooltip(product.weights)">
            {{ product.weights.length }}
          </div>
          <i class="icon-arrow-up-stroke ms-auto fs-20 pointer" [class.rotate-180deg]="!product.isOpen"></i>
        </div>
        <div class="price__details" *ngIf="product.isOpen">
          <div class="price__items">
            <div class="price-item" *ngFor="let weight of product.weights">
              <i class="price-item__icon icon-prices fs-20 mr--8"></i>
              <div class="price-item__column">
                <div class="price-item__title">{{ weight.weight }}</div>
                <div class="price-item__value">{{ weight.price + (weight.price_type === 'fix' ? ' NOK' : ' %')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    
    <div class="price-list__empty-list" *ngIf="!filteredBringProducts.length && !filteredHelthjemProducts && !filteredPostnordProducts">
      <div class="empty-state empty-state--no-height">
        <div class="empty-state__content">
          <div class="empty-state__image empty-state__image--small">
            <img src="assets/images/empty/empty-search.svg" alt="Empty list image">
          </div>
          <div class="empty-state__title" translate>
            Delivery methods not found...
          </div>
          <div class="empty-state__description" translate>
            We couldn't find any results that match your search criteria. Please adjust your search and try again
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="price-list__footer">
    <ap-ui-button-link [text]="allPricesIsOpen ? 'Close all prices' : 'Open all prices'"
                       (click)="openAllPrices()"
                       class="me-auto"></ap-ui-button-link>
    <button class="button button--contained-black button--height-40"
            (click)="closeSidebar.emit(true)" translate>
      Close
    </button>
  </div>
</div>
