import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { Subscription, finalize, mergeMap, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr'

import { ClientInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/client.interface';
import { ClientAppService } from 'src/app/shared/services/client.app.service';
import { CustomerServiceAppService } from '../../services/customer-service.app.service';
import { CustomerSupportTicketSettings } from '../../interfaces/ticket.interface';
import { CustomerServiceService } from 'src/app/services/customer-service-service/customer-service.service';
import { TicketSpecificationsEnum, TicketStatus, TicketTypes, ticketPostServices, ticketSpecifications, ticketTypes } from '../../enums/ticket.enum';
import { LoadingService } from '../../../../shared/services/loading.service';

@Component({
  selector: 'ap-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss']
})
export class TicketFormComponent implements OnInit, OnDestroy {
  private subscription$ = new Subscription();
  shipmentNumber!: string;
  @Input() set shipment(shipment_number: string) {
    if (shipment_number) {
      this.shipmentNumber = shipment_number;
    }
  }
  showTicketForm: boolean = false;
  ticketForm!: FormGroup;

  TicketStatus = TicketStatus;
  currentUser = JSON.parse(localStorage.getItem('user')!);
  attachments: File[] = [];
  attachmentsForView: any[] = [];
  ticketSpecifications = ticketSpecifications;
  ticketPostServices = ticketPostServices;
  TicketSpecificationsEnum = TicketSpecificationsEnum;
  TicketTypes = TicketTypes;
  ticketTypes = ticketTypes;
  submitted: boolean = false;
  ticketSettings!: CustomerSupportTicketSettings;
  clients: ClientInterface[] = [];
  innerHeight!: number;
  activeClient: ClientInterface | null = null;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(public customerServiceAppService: CustomerServiceAppService,
              private customerServiceService: CustomerServiceService,
              private toastr: ToastrService,
              private fb: FormBuilder,
              private sanitizer: DomSanitizer,
              private loadingService: LoadingService,
              private clientAppService: ClientAppService) {
                this.activeClientSubscription();
              }

  ngOnInit(): void {
    this.getData();
    this.innerHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = window.innerHeight;
  }

  getData(): void {
    this.subscription$.add(
      this.clientAppService.allClients().pipe(
        tap((res: ClientInterface[]) => this.clients = res),
        mergeMap(() => this.customerServiceAppService.showTicketForm())
      ).subscribe((settings: CustomerSupportTicketSettings) => {
        this.attachmentsForView = this.attachments = [];
        this.ticketSettings = settings;
        this.showTicketForm = settings.toOpen;
        this.buildForm();
        this.setTicketSettings();
      })
    )
  }

  buildForm(): void {
    this.ticketForm = this.fb.group({
      type: [this.ticketTypes[0].value],
      specification: [this.ticketSpecifications[0]],
      subSpecification: [null],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.maxLength(1000)]],
      client: [this.activeClient ? this.clients.find(item => item.id === this.activeClient!.id) : null],
      postService: [null]
    });

    this.subscription$.add(
      this.ticketForm.valueChanges.subscribe(() => this.submitted = false)
    )

    this.subscription$.add(
      this.ticketForm.get('specification')?.valueChanges.subscribe(() => {
        this.ticketForm.get('subSpecification')?.setValue(null);
        this.ticketForm.get('postService')?.setValue(null);
      })
    )
  }

  activeClientSubscription(): void {
    this.subscription$.add(
      this.clientAppService.activeClient().subscribe((client: ClientInterface | null) => {
        this.activeClient = client;
      })
    )
  }

  setTicketSettings(): void {
    if (this.ticketSettings?.subject) {
      this.ticketForm.get('subject')?.setValue(this.ticketSettings.subject);
      this.ticketForm.get('subject')?.disable();
    }

    if (this.ticketSettings?.specification) {
      this.ticketForm.get('specification')?.setValue(this.ticketSpecifications.find(item => item.value === this.ticketSettings.specification));
      this.ticketForm.get('specification')?.disable();
    }

    if (this.ticketSettings?.postService) {
      this.ticketForm.get('postService')?.setValue(this.ticketPostServices.find(item => item.value === this.ticketSettings.postService));
      this.ticketForm.get('postService')?.disable();
    }

    if (this.ticketSettings?.client_id) {
      let client = this.clients.find(item => item.id === this.ticketSettings.client_id)

      if (client)  {
        this.ticketForm.get('client')?.setValue(this.clients.find(item => item.id === this.ticketSettings.client_id));
        this.ticketForm.get('client')?.disable();
      }
    }
  }

  createTicket(): void {
    this.submitted = true;

    if (this.ticketForm.invalid) {
      return
    }
    this.loadingService.setIsLoading(true)

    const formValue = this.ticketForm.getRawValue();
    const formData = new FormData();

    this.attachments.forEach((file: File) => {
      formData.append('files', file);
    })

    formData.append('type', formValue.type);
    formData.append('specification', formValue.specification.value);
    formData.append('subject', formValue.subject);
    formData.append('message', formValue.message);

    if (formValue.subSpecification) {
      formData.append('sub_specification', formValue.subSpecification.value);
    }

    if (formValue.client) {
      formData.append('client', formValue.client.id);
    }

    if (formValue.postService?.value) {
      formData.append('post_service', formValue.postService.value.toLowerCase());
    }

    this.subscription$.add(
      this.customerServiceService.createTicket(formData).pipe(finalize(() => this.loadingService.setIsLoading(false))).subscribe(res => {
        if (res) {
          this.customerServiceAppService.toResetTicketsList(true);
          this.customerServiceAppService.onToggleTicketForm({ toOpen: false });
          this.buildForm();
          this.submitted = false;
          this.attachmentsForView = this.attachments = [];
        }
      })
    )
  }

  fileChanged(event: any): void {
    this.attachments = [
      ...this.attachments,
      ...Array.from(event.target.files as File[]).filter((file: File) => {
        if (file.size < 5242880) {
          return file
        }

        this.toastr.warning('You can\'t upload files more than 5Mb')
        return
      })
    ];

    this.attachmentsForView = [];
    Array.from(this.attachments).forEach(file => {
      this.attachmentsForView.push({ url: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)), type: file.type, name: file.name})
    });


    if (this.attachments.length > 5) {
      this.attachments.splice(0, this.attachments.length - 5)
      this.attachmentsForView.splice(0, this.attachmentsForView.length - 5)
    }

    let timeout = setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight + 400;
      clearTimeout(timeout);
    }, 0);
  }

  onRemoveAttachment(index: number): void {
    this.attachments.splice(index, 1);
    this.attachmentsForView.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.customerServiceAppService.onToggleTicketForm({ toOpen: false });
    this.attachmentsForView = this.attachments = [];
  }
}
