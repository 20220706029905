import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxSmoothDndModule } from '@libertymp/ngx-smooth-dnd';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ClickOutsideDirective } from './directives/click-outside.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LanguageToggleComponent } from './components/language-toggle/language-toggle.component';
import { UiSlideToggleComponent } from './components/ui/ui-slide-toggle/ui-slide-toggle.component';
import { ButtonToggleGroupDirective } from './directives/button-toggle.directive';
import { UiButtonToggleComponent } from './components/ui/ui-button-toggle/ui-button-toggle.component';
import { UiImagesGalleryComponent } from './components/ui/ui-images-gallery/ui-images-gallery.component';
import { TextLimitationDirective } from './directives/text-limitation.directive';
import { HeaderComponent } from './components/header/header.component';
import { CookiesSettingsModalComponent } from './components/cookies-settings-modal/cookies-settings-modal.component';
import { UiButtonLinkComponent } from './components/ui/ui-button-link/ui-button-link.component';
import { UiAvatarComponent } from './components/ui/ui-avatar/ui-avatar.component';
import { UiLoaderComponent } from './components/ui/ui-loader/ui-loader.component';
import { HeaderNotificationItemComponent } from './components/header-notification-item/header-notification-item.component';
import { AdminpanelCustomTimePipe } from './pipes/time.pipe';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { CreateClientFormComponent } from './components/create-client-form/create-client-form.component';
import { NgxMaskModule } from 'ngx-mask'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { UiPlaceholderLoadingComponent } from './components/ui/ui-placeholder-loading/ui-placeholder-loading.component';
import { UiBlockComponent } from './components/ui/ui-placeholder-loading/components/ui-block/ui-block.component';
import { IterableNumberPipe } from './components/ui/ui-placeholder-loading/pipes/iterable-number.pipe';
import { UiCircleComponent } from './components/ui/ui-placeholder-loading/components/ui-circle/ui-circle.component';
import { UiRectangleComponent } from './components/ui/ui-placeholder-loading/components/ui-rectangle/ui-rectangle.component';
import { AdminpanelReplacePipe } from './pipes/replace.pipe';
import { UiGeneralNotificationStripeComponent } from './components/ui/ui-general-notification-stripe/ui-general-notification-stripe.component';
import { UiInlineAlertComponent } from './components/ui/ui-inline-alert/ui-inline-alert.component';
import { TicketFormComponent } from '../modules/customer-service/components/ticket-form/ticket-form.component';
import { CustomizeTableComponent } from './components/customize-table/customize-table.component';
import { PriceListSidebarComponent } from './components/price-list-sidebar/price-list-sidebar.component';
import { FileUploadUniqueFormComponent } from './components/file-upload-unique-form/file-upload-unique-form.component';
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';
import { GeneralConfirmComponent } from './components/general-confirm/general-confirm.component';
import { UiFiltersComponent } from './components/ui/ui-filters/ui-filters.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmoothDndModule,
    InfiniteScrollModule,
    NgxMaskModule.forRoot(),
    NgxIntlTelInputModule,
  ],
  declarations: [
    TooltipDirective,
    ClickOutsideDirective,
    PageNotFoundComponent,
    LanguageToggleComponent,
    ButtonToggleGroupDirective,
    TextLimitationDirective,
    HeaderComponent,
    CookiesSettingsModalComponent,
    TicketFormComponent,
    HeaderNotificationItemComponent,
    AdminpanelCustomTimePipe,
    AdminpanelReplacePipe,
    EscapeHtmlPipe,
    WelcomePageComponent,
    CreateClientFormComponent,
    IterableNumberPipe,
    CustomizeTableComponent,
    PriceListSidebarComponent,
    FileUploadUniqueFormComponent,
    GeneralConfirmComponent,
    SidebarComponent,
    NoSanitizePipe,
    UiSlideToggleComponent,
    UiButtonToggleComponent,
    UiImagesGalleryComponent,
    UiButtonLinkComponent,
    UiAvatarComponent,
    UiLoaderComponent,
    UiPlaceholderLoadingComponent,
    UiBlockComponent,
    UiCircleComponent,
    UiRectangleComponent,
    UiGeneralNotificationStripeComponent,
    UiInlineAlertComponent,
    UiFiltersComponent,
  ],
  exports: [
    NgSelectModule,
    TooltipDirective,
    BsDatepickerModule,
    PaginationModule,
    ClickOutsideDirective,
    TranslateModule,
    LanguageToggleComponent,
    ButtonToggleGroupDirective,
    TextLimitationDirective,
    HeaderComponent,
    CookiesSettingsModalComponent,
    TicketFormComponent,
    AdminpanelCustomTimePipe,
    AdminpanelReplacePipe,
    EscapeHtmlPipe,
    WelcomePageComponent,
    CreateClientFormComponent,
    IterableNumberPipe,
    NgxIntlTelInputModule,
    CustomizeTableComponent,
    GeneralConfirmComponent,
    InfiniteScrollModule,
    SidebarComponent,
    NoSanitizePipe,
    UiSlideToggleComponent,
    UiButtonToggleComponent,
    UiImagesGalleryComponent,
    UiButtonLinkComponent,
    UiAvatarComponent,
    UiLoaderComponent,
    UiPlaceholderLoadingComponent,
    UiGeneralNotificationStripeComponent,
    UiInlineAlertComponent,
    UiFiltersComponent,
  ]
})
export class SharedModule { }
