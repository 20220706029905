<div class="ticket-form" [class.ticket-form--show]="showTicketForm"
     *ngIf="showTicketForm" #scrollContainer>
  <div class="ticket-form__top">
    <div class="ticket-form__title">
      {{ 'New request' | translate }}
    </div>
    <div class="ticket-form__close" (click)="customerServiceAppService.onToggleTicketForm({ toOpen: false })">
      <i class="icon-download rotate-270deg fs-24"></i>
    </div>
  </div>
  <form class="ticket-form__container" [formGroup]="ticketForm" *ngIf="ticketForm">
    <div class="ticket-form__section">
      <div class="ticket-form__section-title">
        {{ 'Choose the type of request' | translate }}
      </div>
      <ng-select class="dropdown dropdown--outlined-gray ticket-form__select"
                 [items]="ticketTypes" bindLabel="name" bindValue="value"
                 [clearable]="false" [searchable]="false"
                 formControlName="type">
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
    <div class="ticket-form__section">
      <div class="ticket-form__section-title">
        {{ 'Choose request specification' | translate }}
      </div>
      <ng-select class="dropdown dropdown--outlined-gray ticket-form__select"
                 [items]="ticketSpecifications"
                 [clearable]="false" [searchable]="false"
                 formControlName="specification">
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
    <div class="ticket-form__section" *ngIf="ticketForm.get('specification')!.value && ticketForm.get('specification')!.value?.subSpecifications.length">
      <div class="ticket-form__section-title">
        {{ 'Type of issue' | translate }}
      </div>
      <ng-select class="dropdown dropdown--outlined-gray ticket-form__select"
                 [items]="ticketForm.get('specification')!.value.subSpecifications"
                 [clearable]="false" [searchable]="false"
                 formControlName="subSpecification" [placeholder]="'Choose type of issue' | translate">
        <ng-template ng-label-tmp let-item="item">
          {{ '' + item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ '' + item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
    <div class="ticket-form__section" *ngIf="ticketForm.get('specification')!.value && ticketForm.get('specification')!.value!.value === TicketSpecificationsEnum.SHIPMENTS">
      <div class="ticket-form__section-title">
        {{ 'Post provider' | translate }}
      </div>
      <ng-select class="dropdown dropdown--outlined-gray ticket-form__select"
                 [items]="ticketPostServices" bindLabel="name"
                 [clearable]="false" [searchable]="false"
                 formControlName="postService" [placeholder]="'Choose post service' | translate">
      </ng-select>
    </div>
    <div class="ticket-form__section">
      <div class="ticket-form__section-title">
        {{ 'Client (optional)' | translate }}
      </div>
      <ng-select class="dropdown dropdown--outlined-gray ticket-form__select"
                 [items]="clients" bindLabel="name"
                 [clearable]="false" [searchable]="false"
                 formControlName="client" [placeholder]="'Choose client' | translate">
      </ng-select>
    </div>
    <div class="ticket-form__section">
      <div class="ticket-form__section-title"  [class.ticket-form__section-title--red]="submitted && ticketForm.get('subject')!.invalid">
        {{ 'Subject' | translate }} {{ submitted && ticketForm.get('subject')!.invalid ? (' is required' | translate) : '' }}
      </div>
      <div class="ticket-form__input input" [class.input--error]="submitted && ticketForm.get('subject')!.invalid">
        <input type="text" formControlName="subject" placeholder="{{ 'Describe subject' | translate}}">
      </div>
    </div>
    <div class="ticket-form__section">
      <div class="ticket-form__section-title" [class.ticket-form__section-title--red]="submitted && ticketForm.get('message')!.invalid">
        {{ 'Message' | translate }} {{ submitted && ticketForm.get('message')!.invalid ? (' is required' | translate) : '' }}
      </div>
      <div class="ticket-form__textarea textarea" [class.textarea--error]="submitted && ticketForm.get('message')!.invalid">
        <textarea formControlName="message" [rows]="innerHeight < 1100 ? 5 : 8" maxlength="1000"></textarea>
      </div>
    </div>
    <div class="ticket-form__upload-section">
      <div class="ticket-form__upload-title">
        {{ 'Drag and drop your files here,' | translate }}<br>
        {{ 'or' | translate }}<br>
        <span class="ticket-form__upload-title--link">
          {{ 'Click to Upload' | translate }}
        </span>
      </div>
      <div class="ticket-form__upload-title ticket-form__upload-title--small">
        {{ 'Any file type, maximum 5MB each.' | translate }}<br>
        {{ 'Not more than 5 files' | translate }}
      </div>
      <input type="file" multiple #fileInput (change)="fileChanged($event)"
             accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, video/*">
    </div>
    <div class="ticket-form__attachments" *ngIf="attachmentsForView.length">
      <div class="ticket-form__attachment" *ngFor="let file of attachmentsForView; let i = index">
        <div class="ticket-form__remove-attachment">
          <i class="icon-close-stroke fs-16" (click)="onRemoveAttachment(i)"></i>
        </div>
        <img [src]="file.url" *ngIf="file.type.includes('image')">
        <div class="ticket-form__attachment-type" *ngIf="!file.type.includes('image')">
          {{ file.name }}
        </div>
      </div>
    </div>
  </form>
  <div class="ticket-form__buttons">
    <button class="ticket-form__report-button button button--contained-black button--height-40 w-100 mb--10"
            (click)="createTicket()">
       {{ 'Submit request' | translate }}
    </button>
    <button class="ticket-form__report-button button button--contained-gray button--height-40 w-100"
            (click)="customerServiceAppService.onToggleTicketForm({ toOpen: false })">
      {{ "Cancel" | translate }}
    </button>
  </div>
</div>
<div class="ticket-form-backdrop" *ngIf="showTicketForm" (click)="customerServiceAppService.onToggleTicketForm({ toOpen: false })"></div>
