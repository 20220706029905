import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ToastrService } from 'ngx-toastr';
import { filter, forkJoin, mergeMap, tap } from 'rxjs';

import { UploadFileService } from 'src/app/services/upload-file-service/upload-file.service';
import { ClientAppService } from '../../services/client.app.service';
import { ClientInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/client.interface';
import { EdiPrinterInterface } from 'src/app/modules/shipments/interfaces/edi-printer.interface';
import { PrinterService } from 'src/app/services/printer-service/printer.service';
import { ManageClientsService } from 'src/app/services/manage-clients-service/manage-clients.service';
import { emailMask } from '../../helpers/mask.helper';
import { ClientAddressInterface } from 'src/app/modules/shipments/interfaces/client-address.interface';
import { EdiAgreementInterface } from 'src/app/modules/shipments/interfaces/edi-agreement.interface';
import { ShipmentsService } from 'src/app/services/shipments-service/shipments.service';
import { SavedClientDataFromUniqueForm } from '../../interfaces/saved-client-data-from-unique-form.interface';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'ap-file-upload-unique-form',
  templateUrl: './file-upload-unique-form.component.html',
  styleUrls: ['./file-upload-unique-form.component.scss']
})
export class FileUploadUniqueFormComponent {
  activeClient!: ClientInterface | null;
  attachment!: File | any;
  printers: EdiPrinterInterface[] = [];
  ediAPIKey!: string;
  fileUploadForm!: FormGroup;
  clientAddresses: ClientAddressInterface[] = [];
  agreements: EdiAgreementInterface[] = [];
  savedDataFromUniqueForm = JSON.parse(localStorage.getItem('uniqueFormData')!) || [];

  private destoyRef = inject(DestroyRef)

  constructor(private uploadFileService: UploadFileService,
              private clientAppService: ClientAppService,
              private printerService: PrinterService,
              private manageClientsService: ManageClientsService,
              private shipmentsService: ShipmentsService,
              private loadingService: LoadingService,
              private toastr: ToastrService,
              private fb: FormBuilder) {
                this.getClientData();
              }

  fileChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.attachment = target.files![0];
  }

  sendFile(): void {
    let formData = new FormData();
    formData.append('file', this.attachment);
    formData.append('cc', 'rune@profrakt.no');
    formData.append('agreement', this.fileUploadForm.get('agreement')!.value);
    formData.append('email', this.fileUploadForm.get('email')!.value || '');

    if (this.fileUploadForm.get('printer')!.value) {
      formData.append('printer', this.fileUploadForm.get('printer')!.value);
    }

    const savedClientData = this.savedDataFromUniqueForm.find((clientData: SavedClientDataFromUniqueForm) => clientData.client.id === this.activeClient?.id);

    if (savedClientData) {
      this.savedDataFromUniqueForm = this.savedDataFromUniqueForm.map((clientData: SavedClientDataFromUniqueForm) => {
        if (clientData.client.id === this.activeClient?.id) {
          clientData = {client: this.activeClient!, ...this.fileUploadForm.value}
        }
        return clientData;
      })
    } else {
      this.savedDataFromUniqueForm = [...this.savedDataFromUniqueForm, {client: this.activeClient, ...this.fileUploadForm.value }];
    }
    localStorage.setItem('uniqueFormData', JSON.stringify(this.savedDataFromUniqueForm));

    this.uploadFileService.sendFile(formData, this.ediAPIKey, this.fileUploadForm.get('address')!.value).subscribe({
      next: () => {
        this.toastr.info('File was uploaded successfully!', '', {timeOut: 3000, closeButton: true, disableTimeOut: false, positionClass: 'toast-bottom-center'}  )
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.info(err.message, '', {timeOut: 3000, closeButton: true, disableTimeOut: false, positionClass: 'toast-bottom-center'}  )
      }
    })
  }

  private buildForm(): void {
    const savedClientData = this.savedDataFromUniqueForm.find((clientData: SavedClientDataFromUniqueForm) => clientData.client.id === this.activeClient?.id);

    this.fileUploadForm = this.fb.group({
      email: [savedClientData ? savedClientData.email : null, [Validators.required, Validators.pattern(emailMask)]],
      printer: [savedClientData ? this.printers.find((printer: EdiPrinterInterface) => printer.id === savedClientData.printer)?.id : null],
      address: [savedClientData ? this.clientAddresses.find((address: ClientAddressInterface) => address.managership_id === savedClientData.address)?.managership_id : null, Validators.required],
      agreement: [null, Validators.required]
    })

    if (savedClientData && savedClientData.address) {
      this.getAgreements(savedClientData);
    }
  }

  getAgreements(savedClientData: SavedClientDataFromUniqueForm): void {
    this.shipmentsService.getEDIAgreements(this.activeClient?.id!, savedClientData.address).pipe(
      takeUntilDestroyed(this.destoyRef)
    ).subscribe((agreements: EdiAgreementInterface[]) => {
      this.agreements = agreements;
      const selectedAgreement = this.agreements!.find((agreement: EdiAgreementInterface) => agreement.id === savedClientData.agreement);

      if (selectedAgreement) {
        this.fileUploadForm.get('agreement')!.setValue(selectedAgreement.id);
      }
    })
  }

  private addressChangeSubsctiption(): void {
    this.fileUploadForm.get('address')!.valueChanges.pipe(
      tap(() => {
        this.loadingService.setIsLoading(true);
        this.fileUploadForm.get('agreement')!.setValue(null);
      }),
      mergeMap((managership_id: number) => this.shipmentsService.getEDIAgreements(this.activeClient?.id!, managership_id)),
      takeUntilDestroyed(this.destoyRef)
    ).subscribe((agreements: EdiAgreementInterface[]) => {
      this.agreements = agreements;
      this.loadingService.setIsLoading(false);
    })
  }

  private getClientData(): void {
    this.clientAppService.activeClient()
      .pipe(
        tap((client: ClientInterface | null) => {
          this.activeClient = client;

          if ((!(this.activeClient && this.activeClient.id) || this.activeClient && !this.activeClient.has_edi_api_key)) {
            this.printers = [];
          } else {
            this.loadingService.setIsLoading(true);
          }
        }),
        filter(() => !!(this.activeClient && this.activeClient.id && this.activeClient.has_edi_api_key)),
        mergeMap(() => forkJoin([
            this.manageClientsService.getClientsSettings(this.activeClient?.id!),
            this.manageClientsService.getClientAddresses(this.activeClient?.id!),
            this.printerService.getEDIPrinters(this.activeClient?.id!)
          ])
        ),
        takeUntilDestroyed(this.destoyRef)
      ).subscribe({
        next: ([settings, addresses, printers]) => {
          this.printers = printers;
          this.ediAPIKey = settings.edi_api_key;
          this.clientAddresses = addresses;
          this.loadingService.setIsLoading(false);
          this.buildForm();
          this.addressChangeSubsctiption();
        },
        error: (err: HttpErrorResponse) => {
          this.toastr.error(err.message);
          this.loadingService.setIsLoading(false);
      }}
    )
  }
}
