import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { headers, xuserdataHeaders } from '../../shared/helpers/headers.helper';
import { ClientOrdering, FullClient, SuperAdminClientsListResponse, SuperAdminPriceGroup, SuperAdminUpdateClientData } from '../../modules/superadmin/manage-clients/interfaces/superadmin-clients';
import { ClientInterface } from '../../modules/superadmin/manage-clients/interfaces/client.interface';
import { AdditionalExpense } from '../../modules/superadmin/manage-clients/interfaces/additional-expense.interface';
import { ClientAddressInterface } from '../../modules/shipments/interfaces/client-address.interface';
import { AdminpanelReplacePipe } from 'src/app/shared/pipes/replace.pipe';
import { ProductOptionInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/product-option.interface';
import { ClientSettingsInterface } from '../../shared/interfaces/settings/client-settings.interface';

@Injectable({
  providedIn: 'root'
})

export class ManageClientsService {
  private url = environment.baseUrl + '/profrakt';
  local = 'http://127.0.0.1:8000';

  constructor(private http: HttpClient) {}

  getClientsList(search: string, 
                 page_size?: number, 
                 page?: number | null, 
                 ordering?: ClientOrdering | null, 
                 status?: string | null, 
                 invoice_delivery_type?: string | null, 
                 auto_invoicing?: string | null,
                 additional_expenses?: string | null,
                 sales_owner?: string | null,
                 lead_status?: string | null,
                 lead_source?: string | null,
                 edi?: string | null,
                 ecommerce_systems?: string | null,
                 is_onboarded?: string | null): Observable<SuperAdminClientsListResponse> {
    let searchQuery = new AdminpanelReplacePipe().transform(search, '&', '%26');
    let endpoint = `${this.url}/client/?search=${ searchQuery }${ 
                      page_size ? '&page_size=' + page_size : '' }${ 
                      page ? '&page=' + page : '' }${ 
                      ordering ? '&ordering=' + ordering : '' }${ 
                      status ? '&status=' + status : '' }${ 
                      invoice_delivery_type ? '&invoice_delivery_type=' + invoice_delivery_type : '' }${ 
                      auto_invoicing ? '&auto_invoicing=' + auto_invoicing : '' }${
                      additional_expenses ? '&additional_expenses=' + additional_expenses : '' }${
                      sales_owner ? '&sales_owner=' + sales_owner : '' }${
                      lead_status ? '&lead_status=' + lead_status : '' }${
                      lead_source ? '&lead_source=' + lead_source : '' }${
                      edi ? '&edi=' + edi : '' }${
                      ecommerce_systems ? '&ecommerce_systems=' + ecommerce_systems : '' }${
                      is_onboarded ? '&is_onboarded=' + is_onboarded : '' }`;

    return this.http.get<SuperAdminClientsListResponse>(endpoint, { headers: headers()})
  }

  getClientsDetails(client_id: string): Observable<FullClient> {
    return this.http.get<FullClient>(`${this.url}/client/${ client_id }/client-details/`, { headers: headers()})
}

  getAllAvailableClients(): Observable<ClientInterface[]> {
    return this.http.get<ClientInterface[]>(`${this.url}/clients/all/`, { headers: headers()})
  }

  updateClient(data: Partial<FullClient>, id: string): Observable<FullClient> {
    return this.http.patch<FullClient>(`${this.url}/client/${ id }/`, data, { headers: headers()});
  }

  updateClientPriceGroup(data: { price_group: string }, id: string): Observable<SuperAdminPriceGroup> {
    return this.http.post<SuperAdminPriceGroup>(`${this.url}/client/${ id }/price_group/`, data, { headers: headers()});
  }

  getPriceGroupsForDropdown(): Observable<SuperAdminPriceGroup[]> {
    return this.http.get<SuperAdminPriceGroup[]>(`${this.url}/price-group/dropdown/`, { headers: headers()})
  }

  switchCreditMonitoring(id: string, value: boolean): Observable<{ credit_monitoring: boolean, credit_score: string| null }> {
    let data = { credit_monitoring: value }
    return this.http.post<{ credit_monitoring: boolean, credit_score: string | null }>(`${this.url}/client/${ id }/switch_credit_monitoring/`, data, { headers: headers()});
  }

  getCreditEvents(id: string): Observable<any> {
    return this.http.get<any>(`${this.url}/client/${ id }/get_credit_events/`, { headers: headers()});
  }

  mergeClients(data: { main_client: string | number, clients_to_merge: (string | number | undefined)[] }): Observable<any> {
    return this.http.post<any>(`${this.url}/client/merge_clients/`, data, { headers: headers()});
  }

  deleteClient(id: string | number): Observable<any> {
    return this.http.delete<any>(`${this.url}/client/${ id }/`, { headers: headers()});
  }

  setResellerClients(clientID: string | number, body: {clients: (string | number)[]}): Observable<any> {
    return this.http.post(`${this.url}/client/${ clientID }/reseller_clients/`, body, { headers: headers()})
  }

  // AdditionalExpenses
  getAdditionalExpenses(): Observable<AdditionalExpense[]> {
    return this.http.get<AdditionalExpense[]>(`${this.url}/additional_expenses/`, { headers: headers() })
  }

  getAdditionalExpensesByClient(clientID: string | number = ''): Observable<any> {
    return this.http.get(`${this.url}/client/${ clientID }/additional_expenses/`, { headers: headers() })
  }

  updateAdditionalExpensesByClient(clientID: string | number = '', body: { additional_expenses: string[] }): Observable<any> {
    return this.http.post(`${this.url}/client/${ clientID }/additional_expenses/`, body, { headers: headers() })
  }
  // AdditionalExpenses

  // Product Options
  getProductOptions(): Observable<ProductOptionInterface[]> {
    return this.http.get<ProductOptionInterface[]>(`${this.url}/product_options/`, { headers: headers() })
  }

  getProductOptionsByClient(clientID: string | number = ''): Observable<ProductOptionInterface> {
    return this.http.get<ProductOptionInterface>(`${this.url}/client/${ clientID }/product_options/`, { headers: headers() })
  }

  updateProductOptionsByClient(clientID: string | number = '', body: { exclude_from_invoice: string[] }): Observable<null> {
    return this.http.post<null>(`${this.url}/client/${ clientID }/product_options/`, body, { headers: headers() })
  }
  // Product Options

  getClientsSettings(clientID: string | number): Observable<ClientSettingsInterface> {
    return this.http.get<ClientSettingsInterface>(`${this.url}/client/${ clientID }/client-settings/`, { headers: headers()})
  }

  // ADDRESSES START

  getClientAddresses(clientID: string | number): Observable<ClientAddressInterface[]> {
    return this.http.get<ClientAddressInterface[]>(`${this.url}/client/${clientID}/addresses/`, { headers: headers()})
  }

  addClientAddress(body: ClientAddressInterface, clientID: string | number): Observable<ClientAddressInterface> {
    return this.http.post<ClientAddressInterface>(`${this.url}/client/${clientID}/addresses/`, body, { headers: headers()})
  }

  deleteClientAddress(clientID: string | number): Observable<null> {
    return this.http.delete<null>(`${this.url}/client-address/${clientID}/`, { headers: headers()})
  }

  editClientAddress(body: Partial<ClientAddressInterface>, clientID: string | number): Observable<Partial<ClientAddressInterface>>{
    return this.http.patch(`${this.url}/client-address/${clientID}/`, body, { headers: headers()})
  }

  // ADDRESSES END

  getScoreTooltip(credit_score: 'A' | 'AA' | 'AAA' | 'AN' | 'No rating' | 'B' |  'C' | null): string {
    let value = '';

    switch(credit_score) {
      case 'AAA': value = 'Highest credit rating';
      break;

      case 'AA': value = 'Good creditworthiness';
      break;

      case 'A': value = 'Creditworthy';
      break;

      case 'AN': value = 'Newly established company';
      break;

      case 'B': value = 'Credit against collateral';
      break;

      case 'C': value = 'Credit is not recommended';
      break;

      case 'No rating': value = 'Rating not determined';
      break;

      case null: value = 'No rating';
      break;
    }

    return value
  }

  getUserClients(id: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/users/${ id }/user-clients`, { headers: headers()})
  }

  getUserClientsDetails(id: number): Observable<FullClient[]> {
    return this.http.get<FullClient[]>(`${this.url}/users/${ id }/user-clients-with-details`, { headers: headers()})
  }

  toggleClientStatus(id: number | string, is_edi_blocked: boolean): Observable<null> {
    return this.http.post<null>(`${this.url}/client/${ id }/toggle-edi-status/`, {is_edi_blocked}, { headers: headers()})
  }
}
