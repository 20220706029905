import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { filter, mergeMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ClientService } from 'src/app/services/client-service/client.service';
import { ClientAppService } from '../../services/client.app.service';
import { ClientInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/client.interface';
import { ClientPriceGroupResponse } from 'src/app/modules/superadmin/price-groups/interfaces/client-price-group.interface';
import { ClientPriceListProduct } from '../../interfaces/client-price-list-product.interface';
import { ClientPriceGroupProductInterface } from 'src/app/modules/superadmin/price-groups/interfaces/client-price-group-product.interface';
import { ClientPriceListProductWeight } from '../../interfaces/client-price-list-product-weight.interface';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'ap-price-list-sidebar',
  templateUrl: './price-list-sidebar.component.html',
  styleUrls: ['./price-list-sidebar.component.scss']
})
export class PriceListSidebarComponent implements OnInit {
  @Output() closeSidebar = new EventEmitter<boolean>();

  searchFormControl: FormControl = new FormControl(null);
  showPriceListWarning: boolean = true;
  activeClient: ClientInterface | null = null;
  priceGroup: ClientPriceGroupResponse | null = null;
  bringProducts: ClientPriceListProduct[] = [];
  filteredBringProducts: ClientPriceListProduct[] = [];
  helthjemProducts: ClientPriceListProduct[] = [];
  filteredHelthjemProducts: ClientPriceListProduct[] = [];
  postnordProducts: ClientPriceListProduct[] = [];
  filteredPostnordProducts: ClientPriceListProduct[] = [];
  allPricesIsOpen: boolean = false;

  private destroyRef = inject(DestroyRef)

  constructor(private clientService: ClientService,
              private loadingService: LoadingService,
              private clientAppService: ClientAppService) { }

  ngOnInit() {
    this.activeClientSubsription();
    this.searchFormControlSubscription();
  }

  activeClientSubsription(): void {
    this.loadingService.setIsLoading(true);
    this.clientAppService.activeClient().pipe(
      filter((client: ClientInterface | null) => !!client),
      tap((client: ClientInterface | null) => {
        if (!client) {
          this.activeClient = null;
        } else {
          this.activeClient = client;
        }
      }),
      mergeMap(() => {
        return this.clientService.getClientPriceGroup(this.activeClient!.id || 0)
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: ClientPriceGroupResponse[]) => {
      let productNames: string[] = [];

      let products: ClientPriceListProduct[] = [];

      this.priceGroup = res[0];

      this.priceGroup?.products.forEach((product: ClientPriceGroupProductInterface) => {
        productNames.push(product.price_by_weight.name)
      })


      productNames = [...new Set(productNames)];

      products = productNames.map((item: string) => {
        return { name: item, weights: [], isOpen: false, service: ''}
        })

      this.priceGroup?.products.forEach((product: ClientPriceGroupProductInterface) => {
        let i = products.findIndex((item: ClientPriceListProduct) => item.name === product.price_by_weight.name);
        let p = products[i];

        if (p) {
          p['weights'] = [
            ...p['weights'],
            { price: product.price,
              price_type: product.price_type,
              weight: product.price_by_weight.weight,
              service: product.service,
            }]
          p['service'] = product.service;
        }

        products[i] = p;
      })
      
      this.bringProducts = this.filteredBringProducts = products.filter((i: ClientPriceListProduct) => i.service === 'bring')
                                                                .map((product: ClientPriceListProduct) => {
                                                                  switch (product.name) {
                                                                    case 'Ekspress neste dag':
                                                                      product.english_name = 'Express Next Day';
                                                                    break;

                                                                    case 'Henting':
                                                                      product.english_name = 'Pickup';
                                                                    break;

                                                                    case 'Home delivery indoor':
                                                                      product.english_name = 'Home delivery indoor';
                                                                    break;

                                                                    case 'Pakke i postkassen':
                                                                      product.english_name = 'Mailbox Parcel';
                                                                    break;

                                                                    case 'Pakke levert hjem':
                                                                      product.english_name = 'Home Delivery Parcel';
                                                                    break;

                                                                    case 'Pakke til bedrift':
                                                                      product.english_name = 'Business Parcel';
                                                                    break;

                                                                    case 'Pakke til hentested':
                                                                      product.english_name = 'Pick-up Parcel';
                                                                    break;

                                                                    case 'Retur pakke i postkassen':
                                                                      product.english_name = 'Return Mailbox Parcel';
                                                                    break;

                                                                    case 'Stykkgods til bedrift':
                                                                      product.english_name = 'Business Groupage';
                                                                    break;

                                                                    default:
                                                                      product.english_name = product.name;
                                                                    break;
                                                                  }
                                                                  product.weights.sort((a, b) => +(a.weight.split('-'))[0] - +(b.weight.split('-'))[0])
                                                                  return product})
                                                                .sort((a, b) => a.english_name!.localeCompare(b.english_name!));
                                                                
      this.helthjemProducts = this.filteredHelthjemProducts = products.filter((i: ClientPriceListProduct) => i.service === 'helthjem')
                                                                      .map((product: ClientPriceListProduct) => {
                                                                        product.weights.sort((a, b) => +(a.weight.split('-'))[0] - +(b.weight.split('-'))[0])
                                                                        return product})
                                                                      .sort((a, b) => a.name.localeCompare(b.name)),

      this.postnordProducts = this.filteredPostnordProducts = products.filter((i: ClientPriceListProduct) => i.service === 'postnord')
                                                                      .map((product: ClientPriceListProduct) => {
                                                                        product.weights.sort((a, b) => +(a.weight.split('-'))[0] - +(b.weight.split('-'))[0])
                                                                        return product})
                                                                      .sort((a, b) => a.name.localeCompare(b.name));
                                                                      
      this.loadingService.setIsLoading(false);
    })
  }

  searchFormControlSubscription(): void {
    this.searchFormControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((value: string) => {
      if (value) {
        this.filteredBringProducts = this.bringProducts.filter((product: ClientPriceListProduct) => {
          return product.english_name!.toLowerCase().includes(value.toLowerCase())
        })

        this.filteredHelthjemProducts = this.helthjemProducts.filter((product: ClientPriceListProduct) => {
          return product.name!.toLowerCase().includes(value.toLowerCase())
        })

        this.filteredPostnordProducts = this.postnordProducts.filter((product: ClientPriceListProduct) => {
          return product.name!.toLowerCase().includes(value.toLowerCase())
        })
      } else {
        this.filteredBringProducts = this.bringProducts;
        this.filteredHelthjemProducts = this.helthjemProducts;
        this.filteredPostnordProducts = this.postnordProducts;
      }
    })
  }

  onOpenFiltersContextMenu(): void {

  }

  getItemsCountTooltip(weights: ClientPriceListProductWeight[]): string {
    let message = '<span style="font-weight: 600">';

    weights.forEach((item: ClientPriceListProductWeight, index: number) => {
      if (index !== weights.length - 1) {
        message += `${ item.weight }  <span style="font-size: 16px; color: var(--white); margin: 0 4px">${ item.price }</span> <span style="color: var(--white)">${ item.price_type === 'fix' ? 'NOK' : '%'}</span>${ index !== weights.length - 1 ? '</br>' : '' }`
      } else {
        message += `${ item.weight }  <span style="font-size: 16px; color: var(--white); margin: 0 4px">${ item.price }</span> <span style="color: var(--white)">${ item.price_type === 'fix' ? 'NOK' : '%'}</span>${ index !== weights.length - 1 ? '</br></span>' : '' }`
      }
    })

    return message
  }

  openAllPrices(): void {
    this.allPricesIsOpen = !this.allPricesIsOpen;
    this.filteredBringProducts.forEach((product: ClientPriceListProduct) => {
      this.allPricesIsOpen ? product.isOpen = true : product.isOpen = false;
    });

    this.filteredPostnordProducts.forEach((product: ClientPriceListProduct) => {
      this.allPricesIsOpen ? product.isOpen = true : product.isOpen = false;
    });

    this.filteredHelthjemProducts.forEach((product: ClientPriceListProduct) => {
      this.allPricesIsOpen ? product.isOpen = true : product.isOpen = false;
    });
  }
}
