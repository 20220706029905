<div class="client-form">
  <form class="client-form__form"
          *ngIf="companyForm"
          [formGroup]="companyForm">
      <div class="client-form__check-client-section" *ngIf="!selectedCompany">
        <div class="client-form__section mb-0">
          <div class="client-form__input-title">{{ 'Name or organization number' | translate }} <span class="client-form__input-title--red">*</span>
          </div>
          <div class="client-form__input input input--height-48"
              [class.input--error]="alreadyExistError || orgNumberError"
              [class.input--success]="selectedCompany">
            <input type="text" placeholder="12847924192" formControlName="name_org_number_check">
          </div>
          <div class="client-form__input-error client-form__input-error--first" *ngIf="alreadyExistError">
            {{ 'This company is already exist. Check and try again, please.' | translate }}
          </div>
          <div class="client-form__input-error client-form__input-error--first" *ngIf="orgNumberError">
            {{ 'Org number error' | translate }}
          </div>
          <ap-ui-button-link (click)="openTicketForm()"
                             class="ms-auto mt--4"
                             [isSmall]="true"
                             text="{{'Cant find my company' | translate }}"
                             translate>
          </ap-ui-button-link>
        </div>
        <div class="client-form__autocomplete-section" *ngIf="autocompleteResults.length">
          <div class="client-form__autocomplete-item"
               *ngFor="let item of autocompleteResults"
               (click)="checkCompanyInDatabase(item)">
            {{ item.navn | titlecase }} - {{ item.organisasjonsnummer }}
          </div>
        </div>
      </div>

      <ng-container *ngIf="selectedCompany">
        <div class="client-form__second-form-section">
          <div class="client-form__section">
            <div class="client-form__input-title">{{ 'Company Name' | translate }}</div>
              <div class="client-form__input input input--height-48 mr--10"
                  [class.input--error]="submitted && companyForm.get('enterprise_name')?.invalid"
                  [class.input--success]="companyForm.get('enterprise_name')?.valid">
                <input type="text" placeholder="Adminpanel AS" formControlName="enterprise_name">
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('enterprise_name')?.invalid">
              {{ 'Enterprise name required' | translate }}
            </div>
          </div>
          <div class="client-form__section">
            <div class="client-form__input-title">{{ 'Company Registration Number' | translate}}</div>
            <div class="client-form__input input input--height-48"
                [class.input--error]="submitted && companyForm.get('org_number')?.invalid"
                [class.input--success]="companyForm.get('org_number')?.value">
              <input type="text" placeholder="0123456789" formControlName="org_number">
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('org_number')?.invalid">
              {{ 'Organization number required' | translate }}
            </div>
          </div>
          <div class="client-form__section">
            <div class="client-form__input-title">{{ 'Email' | translate}}</div>
            <div class="client-form__input input input--height-48"
                [class.input--error]="submitted && companyForm.get('email')?.invalid"
                [class.input--success]="companyForm.get('email')?.value && companyForm.get('email')?.valid">
              <input type="text" placeholder="example@gmail.com" formControlName="email">
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('email')?.invalid">
              {{ 'Email required' | translate }}
            </div>
          </div>
        </div>
        <div class="client-form__second-form-section">
          <div class="client-form__section">
            <div class="client-form__input-title">{{ 'Address' | translate}}</div>
            <div class="client-form__input input input--height-48"
                [class.input--error]="submitted && companyForm.get('address')?.invalid"
                [class.input--success]="companyForm.get('address')?.valid">
              <input type="text" placeholder="Sandakerveien 64 0484, Nydalen 0422 Oslo" formControlName="address">
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('address')?.invalid">
              {{ 'Address required' | translate }}
            </div>
          </div>
          <div class="client-form__second-form-section">
            <div class="client-form__section">
              <div class="client-form__input-title">{{ 'City' | translate}}</div>
              <div class="client-form__input input input--height-48"
                  [class.input--error]="submitted && companyForm.get('city')?.invalid"
                  [class.input--success]="companyForm.get('city')?.valid">
                <input type="text" placeholder="Oslo" formControlName="city">
              </div>
              <div class="client-form__input-error" *ngIf="submitted && companyForm.get('city')?.invalid">
                {{ 'City required' | translate }}
              </div>
            </div>
            </div>
          <div class="client-form__section">
            <div class="client-form__input-title">{{ 'Zipcode' | translate }}</div>
            <div class="client-form__input input input--height-48"
                [class.input--error]="submitted && companyForm.get('postal_code')?.invalid"
                [class.input--success]="companyForm.get('postal_code')?.valid">
              <input type="text" placeholder="5165" formControlName="postal_code">
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('postal_code')?.invalid">
              <span *ngIf="!companyForm.get('postal_code')?.hasError('required')">
                {{ 'Zipcode invalid' | translate }}
              </span>

              <span *ngIf="companyForm.get('postal_code')?.hasError('required')">
                {{ 'Zipcode required' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="client-form__second-form-section">
          <div class="client-form__section">
            <div class="client-form__input-title">{{ 'Webshop url (optional)' | translate }}</div>
            <div class="client-form__input input input--height-48"
                [class.input--error]="submitted && companyForm.get('webshop_url')?.value && companyForm.get('webshop_url')?.invalid"
                [class.input--success]="companyForm.get('webshop_url')?.value && companyForm.get('webshop_url')?.valid">
              <input type="text" placeholder="Adminpanel.no" formControlName="webshop_url">
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('webshop_url')?.value && companyForm.get('webshop_url')?.invalid">
              {{ 'Webshop url invalid' | translate }}
            </div>
          </div>
          <div class="client-form__section">
            <div class="client-form__input-title">{{ 'Phone' | translate }}</div>
            <div class="client-form__input client-form__input--phone input input--height-48"
                [class.input--error]="submitted && companyForm.get('phone_number')?.invalid"
                [class.input--success]="companyForm.get('phone_number')?.valid">
              <ngx-intl-tel-input [cssClass]="'phone-input'"
                                  [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                  [enableAutoCountrySelect]="false"
                                  [enablePlaceholder]="true"
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="false"
                                  [selectedCountryISO]="CountryISO.Norway"
                                  [maxLength]="15"
                                  [phoneValidation]="false"
                                  formControlName="phone_number"></ngx-intl-tel-input>
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('phone_number')?.hasError('required')">
              {{ 'Phone number required' | translate }}
            </div>
            <div class="client-form__input-error" *ngIf="submitted && companyForm.get('phone_number')?.hasError('validatePhoneNumber')">
              {{ 'Phone number is invalid' | translate }}
            </div>
          </div>
        </div>
    </ng-container>
    <div class="client-form__buttons" [class.mt--100]="!selectedCompany">
      <button class="client-form__create-button button button--contained-black ms-auto"
              (click)="registerCompany()" [disabled]="loading">
      <ng-container *ngIf="!loading">
        {{ 'Create' | translate }}
      </ng-container>
      <ng-container *ngIf="loading">
        <div class="client-form__loading-spinnner">
          <i class="icon-loader fs-30"></i>
        </div>
      </ng-container>
      </button>
    </div>
  </form>
</div>
