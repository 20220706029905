<div class="sidebar"
     [class.sidebar--closed]="isSidebarClosed"
     [class.sidebar--closed-hovered]="isSidebarClosedAndHovered"
     (mouseenter)="isSidebarClosed ? isSidebarClosedAndHovered = true : null"
     (mouseleave)="isSidebarClosed ? isSidebarClosedAndHovered = false : null">
  <div class="sidebar__header">
    <div *ngIf="isSidebarClosed && !isSidebarClosedAndHovered" class="sidebar__logo-short" (click)="navigateTo('/shipments')" ></div>
    <div *ngIf="!isSidebarClosed || (isSidebarClosed && isSidebarClosedAndHovered)" class="sidebar__logo-long" (click)="navigateTo('/shipments')" ></div>
    <div class="sidebar__toggle" (click)="isSidebarClosed = !isSidebarClosed; isSidebarClosedForEmit.emit(isSidebarClosed)">
      <i [ngClass]="isSidebarClosed ? 'icon-sidebar-open' : 'icon-sidebar-close'"></i>
    </div>
  </div>
  <div *ngIf="currentUser" class="sidebar__items">
    <ng-container *ngIf="!userIsInvitedStaff">
      <div class="sidebar__item-container">
        <div class="sidebar__item"
            [class.sidebar__item--active]="activeNavCategory === NavCategoriesEnum.DASHBOARD"
            [class.sidebar__item--disabled]="!currentUser.is_admin && !userIsReseller && !userIsCustomerSupport"
            (click)="setActiveNavCategory(NavCategoriesEnum.DASHBOARD)">
          <i class="icon-dashboard fs-24"></i>
          <div class="sidebar__item-name">{{'Sidebar.Dashboard' | translate}}</div>
        </div>
        <div class="sidebar__divider"></div>
      </div>
    </ng-container>
    <div class="sidebar__item-container"
         [tooltip]="(currentUser.is_admin) ? '' : 'Coming soon'" [placement]="'right'">
      <div class="sidebar__item"
           [class.sidebar__item--disabled]="!currentUser.is_admin"
           (click)="toggleSidebarItem('ecommerce')">
        <i class="icon-products fs-24"></i>
        <ng-container>
          <div class="sidebar__item-name">{{'Sidebar.eCommerce' | translate}}</div>
          <i class="icon-arrow-up-stroke sidebar__arrow-icon fs-20"
             [ngClass]="openedEcommerce ? '' : 'rotate-90deg'"></i>
        </ng-container>
      </div>
      <div *ngIf="openedEcommerce" class="sidebar__item-submenu">
        <div class="sidebar__link"
             (click)="navigateTo('sales-orders')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.ORDERS">
          {{ 'Sidebar.Sales orders' | translate }}
        </div>
        <div *ngIf="currentUser.is_admin"
             class="sidebar__link"
             (click)="navigateTo('products')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.PRODUCT_LIST">
          {{ 'Products' | translate }}
        </div>
        <div *ngIf="false"
             class="sidebar__link"
             (click)="navigateTo('purchase-orders')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.PURCHASE_ORDERS">
          {{ 'Purchase order' }}
        </div>
      </div>
    </div>
    <div class="sidebar__divider"></div>
    <ng-container *ngIf="!environment.production || environment.stage">
      <div class="sidebar__item-container">
        <div class="sidebar__item"
             (click)="toggleSidebarItem('warehousing')">
          <i class="icon-warehouse fs-24"></i>
          <div class="sidebar__item-name">{{'Sidebar.Warehousing' | translate}}</div>
          <i class="icon-arrow-up-stroke sidebar__arrow-icon fs-20"
             [ngClass]="openedWarehousing ? '' : 'rotate-90deg'"></i>
        </div>
        <div *ngIf="openedWarehousing" class="sidebar__item-submenu">
          <div class="sidebar__link"
               (click)="navigateTo('item-movements')"
               [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.ITEM_MOVEMENTS">
            {{ 'Sidebar.Stock movement' | translate}}
          </div>
        </div>
      </div>
      <div *ngIf="!userIsInvitedStaff" class="sidebar__divider"></div>
    </ng-container>
    <ng-container *ngIf="!userIsInvitedStaff">
      <div class="sidebar__item-container">
        <div class="sidebar__item"
            (click)="toggleSidebarItem('logistics')">
          <i class="icon-logistics fs-24"></i>
          <div class="sidebar__item-name">{{ 'Sidebar.Logistics' | translate }}</div>
          <i class="icon-arrow-up-stroke sidebar__arrow-icon fs-20"
            [ngClass]="openedLogistics ? '' : 'rotate-90deg'"></i>
        </div>
        <div *ngIf="openedLogistics" class="sidebar__item-submenu">
          <div class="sidebar__link"
              (click)="navigateTo('shipments')"
              [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.SHIPMENTS">
            {{ 'Sidebar.Shipments' | translate }}
          </div>
          <div class="sidebar__link"
              (click)="navigateTo('contact-book')"
              [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.CONTACT_BOOK">
            {{ 'Sidebar.Contact Book' | translate }}
          </div>

          <!-- Hide old ivoices section 21.06.2024. Will remove in future -->
          <!-- <div class="sidebar__link"
              (click)="navigateTo('invoices')"
              [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.INVOICES">
            {{ 'Sidebar.Invoices (Old)' | translate }}
          </div> -->
          <!-- Hide old ivoices section 21.06.2024. Will remove in future -->

          <div class="sidebar__link"
              (click)="navigateTo('visma-invoices')"
              [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.VISMA_INVOICES">
            {{ 'Sidebar.Invoices' | translate }}
          </div>
        </div>
        <div class="sidebar__divider"></div>
      </div>
    </ng-container>
    <div *ngIf="currentUser && (currentUser.is_admin || userIsCustomerSupport || userIsReseller)" class="sidebar__item-container">
      <div class="sidebar__item"
           (click)="toggleSidebarItem('superadmin')">
        <i [class]="superadminOrResellerIconClass + ' fs-24'"></i>
        <ng-container *ngIf="!isSidebarClosed || (isSidebarClosed && isSidebarClosedAndHovered)">
          <div class="sidebar__item-name">
            <ng-container *ngIf="currentUser.is_admin || userIsCustomerSupport">
              {{ 'Sidebar.Superadmin' | translate }}
            </ng-container>
            <ng-container *ngIf="userIsReseller && !currentUser.is_admin && !userIsCustomerSupport">
              {{ 'Sidebar.Reseller Admin' | translate }}
            </ng-container>
          </div>
          <i class="icon-arrow-up-stroke sidebar__arrow-icon fs-20"
             [ngClass]="openedSuperadmin ? '' : 'rotate-90deg'"></i>
        </ng-container>
      </div>
      <div *ngIf="openedSuperadmin" class="sidebar__item-submenu">
        <div class="sidebar__link"
             (click)="navigateTo('price-groups')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.PRICE_GROUPS">
          {{ 'Sidebar.Price groups' | translate }}
        </div>
        <div *ngIf="currentUser.is_admin || userIsCustomerSupport" class="sidebar__link"
             (click)="navigateTo('manage-clients')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.CLIENTS_LIST">
          {{ 'Sidebar.Manage Clients' | translate }}
        </div>
        <div *ngIf="currentUser.is_admin || userIsCustomerSupport" class="sidebar__link"
             (click)="navigateTo('manage-users')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.MANAGE_USERS">
          {{ 'Sidebar.Manage Users' | translate }}
        </div>
        <div *ngIf="currentUser.is_admin" class="sidebar__link"
             (click)="navigateTo('manage-permissions')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.MANAGE_PERMISSIONS">
          {{ 'Sidebar.Manage Permissions' | translate }}
        </div>
        <div *ngIf="currentUser.is_admin" class="sidebar__link"
             (click)="navigateTo('manage-printers')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.MANAGE_PRINTERS">
          {{ 'Sidebar.Manage Printers' | translate }}
        </div>
        <div *ngIf="currentUser.is_admin" class="sidebar__link"
             (click)="navigateTo('manage-translates')"
             [class.sidebar__link--active]="activeNavCategory === NavCategoriesEnum.MANAGE_TRANSLATES">
          {{ 'Sidebar.Manage translates' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar__footer">
    <ng-container *ngIf="currentUser && !userIsInvitedStaff">
      <div *ngIf="currentUser.is_admin" class="sidebar__item-container mb--8">
        <div class="sidebar__item position-relative"
             [class.sidebar__item--active]="activeNavCategory === NavCategoriesEnum.INTEGRATIONS"
             (click)="setActiveNavCategory(NavCategoriesEnum.INTEGRATIONS)">
          <i class="icon-integration fs-24"></i>
          <div *ngIf="!isSidebarClosed || (isSidebarClosed && isSidebarClosedAndHovered)" class="sidebar__item-name">
            {{'Integrations' | translate}}
          </div>
          <div class="status status--height-20 sidebar__new">{{ 'New' | translate }}</div>
        </div>
      </div>
      <div class="sidebar__item-container mb--8">
        <div class="sidebar__item"
             [class.sidebar__item--active]="activeNavCategory === NavCategoriesEnum.SETTINGS"
             (click)="setActiveNavCategory(NavCategoriesEnum.SETTINGS)">
          <i class="icon-settings fs-24"></i>
          <div *ngIf="!isSidebarClosed || (isSidebarClosed && isSidebarClosedAndHovered)" class="sidebar__item-name">
            {{'Sidebar.Settings' | translate}}
          </div>
        </div>
      </div>
    </ng-container>
    <div class="sidebar__item-container">
      <div class="sidebar__item"
           [class.sidebar__item--active]="activeNavCategory === NavCategoriesEnum.CUSTOMER_SERVICE"
           (click)="setActiveNavCategory(NavCategoriesEnum.CUSTOMER_SERVICE)">
        <i class="icon-customer-service fs-24"></i>
        <div *ngIf="!isSidebarClosed || (isSidebarClosed && isSidebarClosedAndHovered)" class="sidebar__item-name">
          {{'Sidebar.Customer Service' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
