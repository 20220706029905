import { Location } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  lang: 'en' | 'no' = 'no';
  lctn = this.location;

  constructor(private router: Router,
              private location: Location) {}

  navigateToRoot() {
    this.router.navigate(['/'])
  }

}
