<div class="page-not-found">
  <div class="page-not-found__logo" (click)="navigateToRoot()"></div>
  <div class="page-not-found__drone"></div>
  <div class="page-not-found__image"></div>
  <div class="page-not-found__title">
    {{ 'Page Not Found.Error 404. Page not found' | translate }}
  </div>
  <div class="page-not-found__title page-not-found__title--small">
    {{ 'Page Not Found.This page doesnt exist or was removed. Please navigate to the home page.' | translate }}
  </div>
  <button class="button button--contained-black" (click)="lctn.back()">
    {{ 'Back' | translate }}
  </button>
</div>
