import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, tap } from 'rxjs';

import { headers } from '../../shared/helpers/headers.helper';
import { ClientInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/client.interface';
import { ClientStatusEnum } from 'src/app/modules/superadmin/manage-clients/enums/client-statuses.enum';
import { ClientAppService } from 'src/app/shared/services/client.app.service';
import { ClientDetailsInterface } from './client.interface';
import { ClientPriceGroupResponse } from 'src/app/modules/superadmin/price-groups/interfaces/client-price-group.interface';
import { DefaultResponse } from '../shared/interfaces/responses.interface';
import { ClientIntegrationInfoInterface } from '../../shared/interfaces/integrations/client-integration-info.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ClientService {
  private url = environment.baseUrl + '/profrakt';
  local = 'http://127.0.0.1:8000'

  constructor(private http: HttpClient,
              private clientAppService: ClientAppService) {}

  registerClient(data: {
    name: string,
    org_number: string,
    webshop_url: string,
    address: {
      address_line1: string,
      postal_code: string,
      city: string,
      country: string }[],
    registered_from: number
    },
  ): Observable<any> {
    return this.http.post<any>(`${ this.url }/clients/`, data, { headers: headers() })
  }

  getClients(activeClientID: string | number = '', status?: ClientStatusEnum, toUpdateInClientsAppService: boolean = true): Observable<ClientInterface[]> {
    return this.http.get<ClientInterface[]>(`${this.url}/clients/` + (activeClientID ? `?reseller_client_id=${activeClientID}` : ``) + (status ? `?status=${status}` : ``), { headers: headers()}).pipe(
      tap((clients: ClientInterface[]) => {
        if (toUpdateInClientsAppService) {
          this.clientAppService.setClients(clients);
        }
      })
    );
  }

  getResellerClients(reseller_client_id: string | number): Observable<ClientInterface[]> {
    return this.http.get<ClientInterface[]>(`${this.url}/clients/${ reseller_client_id ? `?reseller_client_id=` + reseller_client_id : ''}`, { headers: headers()});
  }

  getClientsForReseller(): Observable<ClientInterface[]> {
    return this.http.get<ClientInterface[]>(`${this.url}/clients/for_reseller/`, { headers: headers()});
  }

  getClientDetails(id: string | number): Observable<ClientDetailsInterface> {
    return this.http.get<ClientDetailsInterface>(`${this.url}/clients/${ id }/details/`, { headers: headers()});
  }

  editClientInfo(id: string | number, body: ClientDetailsInterface): Observable<ClientDetailsInterface> {
    return this.http.patch<ClientDetailsInterface>(`${this.url}/clients/${ id }/details/`, body, { headers: headers()});
  }

  getClientPriceGroup(client: number | string): Observable<ClientPriceGroupResponse[]> {
    return this.http.get<ClientPriceGroupResponse[]>(`${this.url}/clients/client_price_groups/?client=${ client }`, { headers: headers()});
  }

  toggleAutoSaveContacts(client_id: number | string): Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.url}/clients/${ client_id }/toggle-auto-save-contacts/`, { headers: headers() });
  }

  unpaidInvoices(client_id: number | string): Observable<{ have_unpaid_invoices: boolean }> {
    return this.http.get<{ have_unpaid_invoices: boolean }>(`${this.url}/clients/${ client_id }/unpaid-invoices/`, { headers: headers() });
  }

  getClientIntegrationInfo(companyID: number): Observable<ClientIntegrationInfoInterface[]> {
    return this.http.get<ClientIntegrationInfoInterface[]>(`${this.url}/companies/${companyID}/settings/integrations`, { headers: headers()})
  }
}
